export class Question {
  desc: string;
  set: string;
  course: string;
  ytLink: string;
  imgsrc: string;
  comment: string;
}

export class Filter {
  course: string;
  set: string;
  question: string;
}
