import { Component } from '@angular/core';
import { Question, Filter } from './shared/models/question.model';
import * as json from '../assets/questions.json';





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Hamro V';
  public questions: Array<Question>;
  public filter: Filter;
  public panelList: Array<Question>;
  public pageHeading: string;
  public qns: string [];
  public vdosrc: string;

  private setPageHeading(set: string) {
    return 'SEE - ' + set;
  }

  constructor() {
    this.readJSON();
    this.filter = new Filter();
    this.filter.course = 'SEE Math';
    this.filter.set = '2076 Math Model Set 1';

    this.panelList = this.lookup(this.questions, 'questions', this.filter);
    this.pageHeading = this.setPageHeading(this.filter.set);
  }

  public  getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}
  public getCourseSets(course: string ){
    this.filter.course = course;
    const action = 'sets';
    const cloneQuestions = [...this.lookup(this.questions, action, this.filter)];
    this.qns = [];
    cloneQuestions.map(item => {
      this.qns.push(item.set);
    });
    const mathsets = ['2076 Math Model Set 1', '2076 Math Model Set 1 nepali',
      '2075 Math Province 3 CP', '2075 Math Province 5 EP nepali', '2075 Math Province 5 EP',
      '2075 Math Province 2 BP nepali', '2075 Math Province 2 BP', '2075 Math Province 1 AP nepali',
      '2075 Math Province 1 AP', '2074 Math Province 3 CC'];
    if (course === 'SEE Math') {
      return mathsets;
    }
    else {
      return Array.from(Array.from(new Set(cloneQuestions.map((item) => {
        return item.set;
      })))).sort().reverse();
    }
  }

  public getSetQuestions(course: string, set: string){
    this.filter.course = course;
    this.filter.set = set;
    this.panelList = this.lookup(this.questions, 'questions', this.filter);
  }

  public getYTLink(course: string, set: string, question: string) {
    console.log('helo yt');
  }


  public lookup(questions: Array<Question>, action: string, param: Filter){
    if (action === 'sets' ) {
      return questions.filter(q => q.course === param.course).map(item => {
        return item;
      });
    }
    if (action === 'questions' ) {
      this.pageHeading = this.setPageHeading(param.set);
      return questions.filter(q => q.course === param.course && q.set === param.set).map(item => {
        return item;
      });
    }
    if (action === 'youtube-link' ){
      return questions.filter(q => q.course === param.course && q.set === param.set && q.desc === param.question).map(item => {
        return item;
      });
    }
  }

  public readJSON(){
    const data = (json as any).default;
    this.questions = [] ;
    let comment = '';
    const conditions = {
      number: 'Number swap',
      name: 'Variable swap',
      same: 'Same'
    };
    data.forEach(e => {
      comment = e.Comments;
      // 4 cases to to setup comments
      if (comment === conditions.name ) {
        e.Comments = 'Similar to ' + e.Compare + '; difference is it uses different name/symbol.';
      }
      else if (comment === conditions.number ) {
        e.Comments = 'Similar to ' + e.Compare + '; difference is it uses different numbers.';
      }
      else if (comment === conditions.same) {
        e.Comments = 'Same as ' + e.Compare + '. Click below';
      }
      else if (e.Similarity !== '' && (comment !== conditions.number || comment !== conditions.same || comment !== conditions.name ) ) {
        e.Comments = 'Same as ' + e.Compare + '. ' + e.Comments;
      }

      this.questions.push(
        {desc: e.Title, set: e.Set, course: e.Course, ytLink: this.getId(e.Link), imgsrc: e.ImageSrc, comment: e.Comments}
      );
    });
  }



  public testfunc(v: string) {
    this.vdosrc = v;

  }


}
