
<div class="container" style="padding: 0px !important;margin-left: auto;margin-right: auto;" >

    <nav class="yamm navbar navbar-expand-lg navbar-light bg-light" style="margin-left: auto;">

      <a class="navbar-brand" href="#">
        HamroV <span id="extra-text" style="font-size: small;">- SEE Math & Science videos</span>
        <img src="assets/flag.jpg" alt="" style="display: inline;border-radius: 50%;margin-left: 15px; max-width: 25px;">
      </a>


      <button id="collapsibleBtn" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo221" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item dropdown yamm-fw ">

          <a class="nav-link dropdown-toggle ham" href="#"  role="button" data-toggle="dropdown" style="display: inline;color: #1865f2 !important;
          /* font-size: 16px !important; */
          line-height: 20px !important;
          font-weight: 700 !important;
          padding: 0px !important;">
            Question Sets



          </a>
          <div class="dropdown dropdown-menu p-0" data-toggle="dropdown">
            <div class="grid-demo" >
              <div class="row">

                <div class="col-sm-3 cs-set-list" style="text-align: left;">
                  <span style="text-align: right;">SEE Math</span>
                  <li *ngFor="let entries of this.getCourseSets('SEE Math')" ><a href="#" class="hv-analytics"  (click)="this.getSetQuestions('SEE Math',entries)">{{entries}}</a></li>
                </div>

                <div class="col-sm-3 cs-set-list" style="text-align: left;">
                  <span style="text-align: right;">SEE Science</span>
                  <li *ngFor="let entries of this.getCourseSets('SEE Science')" ><a href="#" class="hv-analytics" (click)="this.getSetQuestions('SEE Science',entries);">{{entries}}</a></li>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

    </div>
  </nav>





  <div class="collapse navbar-collapse" id="navbarTogglerDemo221" >
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <div class="grid-demo" >
        <div class="row" >

          <div class="col-sm-3 cs-set-list" style="text-align: left;">
            <span style="text-align: right;">SEE Math</span>
            <li *ngFor="let entries of this.getCourseSets('SEE Math')" ><a href="#" class="hv-analytics" onclick='$("#collapsibleBtn").click();' (click)="this.getSetQuestions('SEE Math',entries)">{{entries}}</a></li>
          </div>

          <div class="col-sm-3 cs-set-list" style="text-align: left;">
            <span style="text-align: right;">SEE Science</span>
            <li *ngFor="let entries of this.getCourseSets('SEE Science')" ><a href="#" class="hv-analytics" onclick='$("#collapsibleBtn").click();' (click)="this.getSetQuestions('SEE Science',entries);">{{entries}}</a></li>
          </div>

        </div>
      </div>
    </ul>
  </div>




</div>

<!-- Modal -->

<style>
  .modal-content iframe{
      margin: 0 auto;
      display: block;
  }
</style>


<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <p id="madho">

        </p>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowfullscreen allowscriptaccess="always"
            allow="autoplay"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- modal video -->


    <div class="container" >
      <div class="row">
        <div class="col-md-12 col-xs-12 col-sm-12 card-questions">
            <br>
            <p class="lead">{{ pageHeading }}</p>




<div *ngFor="let entries of panelList"  class="card"  style="display: block;">
  <div class="card-body">

    <p class="card-text">{{ entries.desc }}</p>


  </div>
  <a  *ngIf="entries.imgsrc.length>0" type="button" style="color: #007bff;" class="video-btn0"    onclick="openModal(this)" data-toggle="modal"  id='https://www.youtube.com/embed/{{ entries.ytLink }}'   data-target="#myModal">
    <img  class="responsive" style="max-width: 700px;width: 100%;height: auto;" src="{{entries.imgsrc}}">
  </a>

  &nbsp;<p  class="card-text" [innerHTML]="entries.comment"></p>
  <a type="button" style="color: #007bff; margin-left: 5px;" class="video-btn0"    onclick="openModal(this)" data-toggle="modal"  id='https://www.youtube.com/embed/{{ entries.ytLink }}'   data-target="#myModal">Video</a>

</div>



<div *ngFor="let entries of panelList" class="card" style="display: none;">
  <div class="card-body">
    <p class="card-text">{{ entries.desc }}</p>
    <div *ngIf="entries.imgsrc.length>0">
      <div class="row">
        <style>.responsive {
  width: 100%;
  height: auto;
}</style>
        <div class="col-xs-12">
          <a type="button" style="color: #007bff;" class="video-btn0"    onclick="openModal(this)" data-toggle="modal"  id='https://www.youtube.com/embed/{{ entries.ytLink }}'   data-target="#myModal">
      <img  class="responsive" style="max-width: 700px;" src="{{entries.imgsrc}}">
    </a>
    </div>
    </div>
    <a type="button" style="color: #007bff;" class="video-btn0"    onclick="openModal(this)" data-toggle="modal"  id='https://www.youtube.com/embed/{{ entries.ytLink }}'   data-target="#myModal">Video</a>
  </div>
</div>
          </div>

          </div>
    </div>
    <footer class="container" style="margin-top: 50px;">
      <div class="row">
        <div class="col-6 col-md">
          <ul class="list-unstyled text-small">
            <li><a class="text-muted" href="#"  data-toggle="modal"  id='about-link' data-target="#about">About</a></li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md">
          <span style="text-align: center;"><small class="d-block mb-3 text-muted">&copy; 2020 Gyan Initiative</small></span>
        </div>
      </div>
    </footer>

<!-- About Modal -->
<div class="modal fade" id="about" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" >
    <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">About</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

      <div class="modal-body">

          <p>
            HamroV provides videos to help students in Nepal learn better and do well in exams. We currently have videos for SEE
            Math and Science models exams and past exams. In the future, we will cover other subjects and exams.
          </p>
          <p>
            HamroV is made possible by Gyan Initiative (<a href="https://www.gyaninitiative.org/" target="_blank">www.gyaninitiative.org</a>), the University of Houston community and countless
            other volunteers from around the world.
          </p>
          <p>
            Contact: contact@gyaninitiative.org
          <p>

      </div>
    </div>
  </div>
</div>
<!-- About Modal Ends here -->



